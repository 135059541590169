import Vue from 'vue';
import App from './App.vue';
import {router} from './router';
import {i18n} from './plugin/i18n';
import {store} from './vuex/store';
import vuetify from '@/plugin/vuetify';
import { createPinia, PiniaVuePlugin } from 'pinia'
import {configureApi} from '@/api';
import '@/plugin/tippy'
import './plugin/vuetify';
import './prototype'
import 'vue-tsx-support/enable-check'
import VueClipboard from 'vue-clipboard2'
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
// style
//import 'selectable-ts/css/styles.css';
import '@/sass/main.scss';
import 'nprogress/nprogress.css';

import { Route, RawLocation } from 'vue-router';
import { useSharingStore } from '@/store/sharing';
import { useFolderStore } from '@/store/folder';

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: 'contedisk-frontend@' + (process.env.VUE_APP_VERSION ?? '0.1.0'),
    environment: process.env.VUE_APP_ENV ?? 'dev',
    logErrors: true,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
    tracesSampleRate: 1.0,
});

Vue.use(PiniaVuePlugin);
Vue.use(VueClipboard);

const pinia = createPinia();

Vue.config.productionTip = false;

export const $http = configureApi();
export const state = store;

store.watch(store.getters['User/getUser'], (user: { [key: string]: number | string }) => {
    if (!user.id) {
        Sentry.setUser(null);
        return;
    }

    Sentry.setUser({
        id: user.id as string,
        email: user.email as string,
        Role: user.role,
        Groups: user.groups
    });
});

export const refreshCatch = (url: string) => {
    router.push(`/${url}`).then()
}

const authGuard = (to: Route, from: Route, next: (to?: RawLocation) => void): void => {
    if (to.matched.some(url => url.meta.login)) {
        if (store.getters['Auth/GET_LOGIN']()) {
            next();
        } else {
            next('/login');
        }
    } else {
        if (to.meta.securityRoles) {
            const currentRoles: string[] = [];
            if (!store.getters['Auth/isAuthenticated']()) {
                currentRoles.push('unauthenticated');
            }

            if (currentRoles.find(x => to.meta.securityRoles.includes(x))) {
                // Есть пересечения.
                next();
            } else {
                next('/');
            }
        } else {
            next();
        }
    }
}

let wasPublicLinkGuardUsed = false;
router.beforeEach((to, from, next): void => {
    if (wasPublicLinkGuardUsed) {
        return authGuard(to, from, next);
    }

    wasPublicLinkGuardUsed = true; // Устанавливаем флаг сразу, в независимости от результата.

    let token: string | null = null;
    // Переходим ли мы на /sharing/{token} или просто смотрим в локальное хранилище.
    let isDirectSharingLinkUsed = false;
    if (to.name === 'sharing' && to.params.token) {
        token = to.params.token;
        isDirectSharingLinkUsed = true;
    } else {
        token = sessionStorage.getItem('contedisk.sharing.token');
    }

    if (token) {
        $http.get(`/sharing/${token}`).then(({ data }) => {
            sessionStorage.setItem('contedisk.sharing.token', token as string);
            $http.defaults.headers.common['X-Public-Link'] = token;
            const folderId = data.result.folderId;
            const permission = data.result.permission;

            const store = useSharingStore();
            store.permission = permission;
            store.folderId = folderId;
            store.token = token;

            if (isDirectSharingLinkUsed) {
                next({
                    name: 'files',
                    params: {
                        id: folderId
                    }
                });
            } else {
                authGuard(to, from, next);
            }
        }).catch(() => {
            sessionStorage.removeItem('contedisk.sharing.token');

            const folderId = useFolderStore().getAvailableRootFolder();
            if (!folderId) {
                return next({ name: 'Login' });
            }

            next({
                name: 'files',
                params: {
                    id: folderId as unknown as string
                }
            });
        });

        return;
    }

    authGuard(to, from, next);
});

new Vue({
    router,
    vuetify,
    store,
    i18n,
    pinia,
    render: (h) => h(App),
}).$mount('#app');
