import {Mutations, Module, Actions, Getters} from 'vuex-smart-module';

class ModalState {
    public modalType: string | null = null;

}

class ModalMutations extends Mutations<ModalState>{
    public LOAD_MODAL(payload: string | null) {
        this.state.modalType = payload;
    }

}

class ModalActions extends Actions<ModalState, ModalGetters, ModalMutations, ModalActions>{

}

class ModalGetters extends Getters<ModalState>{
    
}

export const Modal = new Module({
    state: ModalState,
    mutations: ModalMutations,
    actions: ModalActions,
    getters: ModalGetters
});