import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api';

export const useSharingStore = defineStore('sharing', () => {
    const token = ref<string | null>(null);
    const folderId = ref<number | null>(null);
    const permission = ref<'read' | 'write' | null>(null);
    const canRead = computed(() => permission.value === 'read' || permission.value === 'write');
    const canWrite = computed(() => permission.value === 'write');

    return {
        token,
        folderId,
        permission,
        canRead,
        canWrite
    }
});
