import login from '@/api/login';
import user from '@/api/user';
import folger from '@/api/folder';
import search from '@/api/Search';
import b2b from '@/api/b2b';
import {Toast} from '@/plugin/toast';
import {RequestStatus} from '@/constants/Api';

export interface ISetState {
  field: string[];
  value: number | string | boolean | any;
}

interface IVuexMixins {
  _setState(data: any, state: ISetState): void;

  toast(type: RequestStatus, title: string): void;
}

export class CVuexMixins implements IVuexMixins {
  public api = {
    login, user, folger, search, b2b
  }

  _setState(data: any, state: ISetState) {
    const field = state.field;
    const value = state.value;

    if (field.length === 1) data[field[0]] = value !== 'ALL' ? value : null;
    if (field.length === 2) data[field[0]][field[1]] = value !== 'ALL' ? value : null;
    if (field.length === 3) data[field[0]][field[1]][field[2]] = value !== 'ALL' ? value : null;
    if (field.length === 4) data[field[0]][field[1]][field[2]][field[3]] = value !== 'ALL' ? value : null;

    return data
  }

  toast(type: RequestStatus, title: string) {
    Toast.fire({ icon: type, title: title })
  }

  sort(key: string, order: string) {
    return function (a: any, b: any) {
      // eslint-disable-next-line no-prototype-builtins
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

      const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) comparison = 1
      if (varA < varB) comparison = -1;

      return (order === 'desc') ? (comparison * -1) : comparison
    }
  }
}
