import {Actions, Getters, Mutations, Module} from 'vuex-smart-module';
import {IAuthUser, IITokenModel, IResultLogin} from '@/vuex/Auth/ILogin';
import {refreshCatch} from '@/main';
import {VuexMixins} from '@/prototype';
import {ISetState} from '@/mixins/vuex_mixins';

const defaultToken = (): IITokenModel => ({
    createdAt: '',
    token: '',
})

const defaultAuth = (): IAuthUser => ({
    email: '',
    password: ''
})

class AuthState {
    login = !!localStorage.getItem('accessToken');
    storeAuth = defaultAuth();
    accessToken: IITokenModel = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken') as string) as IITokenModel : defaultToken()
    refreshToken = localStorage.getItem('refreshToken') ? JSON.parse(localStorage.getItem('refreshToken') as string) : defaultToken();
    error: Record<string, string> = {};
    refresh = false;
}

class AuthMutations extends Mutations<AuthState> {
    public LOGIN_ERROR(payload: Record<string, string>) {
        this.state.error = payload
    }

    public LOGOUT() {
        this.state.accessToken = defaultToken()
        this.state.refreshToken = defaultToken();
        //localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        this.state.login = false;
        refreshCatch('login')
    }

    public LOGIN(payload: IResultLogin) {
        //vuex
        this.state.accessToken = payload.accessToken;
        this.state.refreshToken = payload.refreshToken;
        //localStorage
        localStorage.setItem('accessToken', JSON.stringify(payload.accessToken));
        localStorage.setItem('refreshToken', JSON.stringify(payload.refreshToken));
        this.state.login = true;
    }

    public CHANGE_STORE_LOGIN(payload: ISetState) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.state.storeAuth = VuexMixins._setState(this.state.storeAuth, payload) as IAuthUser;
        delete this.state.error[Object.keys(payload)[0]];
    }

    refresh(refresh: boolean){
        this.state.refresh = refresh;
    }

}

class AuthActions extends Actions<AuthState, AuthGetters, AuthMutations, AuthActions> {
    async SET_LOGIN(payload: IAuthUser) {
        return await new Promise<void>((resolve, reject) => {
            VuexMixins.api.login.login(payload).then(res => {
                this.mutations.LOGIN(res.data.result)
                if(res.data.backUrl) window.location = res.data.backUrl
                resolve()
            }).catch(res => {
                this.mutations.LOGIN_ERROR(res.response.data.result)
                reject(res)
            })
        })
    }

     refresh() {
        this.mutations.refresh(true)
        return new Promise<void>((resolve, reject) => {
            VuexMixins.api.login.refresh(this.state.refreshToken.token).then(res => {
                this.mutations.LOGIN(res.data.result)
                this.mutations.refresh(false)
                resolve()
            }).catch(() => {
                this.mutations.LOGOUT()
                reject()
            })
        })
    }
}

class AuthGetters extends Getters<AuthState> {
    public isAuthenticated() {
        return this.state.login;
    }

    public GET_ACCESS_TOKEN() {
        return this.state.accessToken;
    }

    public GET_LOGIN() {
        return this.state.login
    }
}

export const Auth = new Module({
    state: AuthState,
    mutations: AuthMutations,
    getters: AuthGetters,
    actions: AuthActions
});
