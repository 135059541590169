import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'Disk',
        redirect: {
            path: '/files/1',
        },
        component: () => import('@/views/Disk/index.vue'),
        children: [
            {
                path: '/files/:id(\\d+)',
                name: 'files',
                component: () => import('@/views/Disk/page/Folder/index.vue'),
                props: true
            },
            {
                path: '/favorites',
                name: 'favorites',
                component: () => import('@/views/Disk/page/Favorites/index.vue'),
                props: true,
            },
            {
                path: '/recent',
                name: 'recent',
                component: () => import('@/views/Disk/page/Recent/index.vue'),
                props: true,
            },
            {
                path: '/marked/:color',
                name: 'marked',
                component: () => import('@/views/Disk/page/Marked/index.vue'),
                props: true,
            },
            {
                path: '/basket',
                name: 'basket',
                component: () => import('@/views/Disk/page/Basket/index.vue'),
                props: true,
            },
            {
                path: '/search',
                name: 'search',
                component: () => import('@/views/Disk/page/Search/index.vue'),
                props: (route) => ({ search: route.query.str }),
            },
        ]
    },
    {
        name: 'sharing',
        path: '/sharing/:token',
        component: () => import('@/views/Disk/index.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            securityRoles: ['unauthenticated']
        },
        component: () => import('@/views/Login/index.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        meta: {
            securityRoles: ['unauthenticated']
        },
        component: () => import('@/views/Register/index.vue'),
    },
    {
        path: '/b2b/clients/:name',
        name: 'b2b',
        component: () => import('@/views/B2B/Client/index.vue'),
        props: route => ({key: route.query.key, children: route.query.children})
    },
    {
        path: '/b2b/admins/:name',
        name: 'admins',
        component: () => import('@/views/B2B/Admin/index.vue'),
        props: route => ({key: route.query.key})
    },
    {
        path: '/admin',
        redirect: {
            path: '/admin/users',
        },
        component: () => import('@/views/Admin/index.vue'),
        children: [
            {
                path: '/admin/users',
                name: 'users',
                component: () => import('@/views/Admin/Users/UsersTable.vue'),
            },
            {
                path: '/admin/groups',
                name: 'groups',
                component: () => import('@/views/Admin/groups/Groups.vue'),
            },
        ]
    },
    {
        path: '/lost',
        name: 'lost',
        component: () => import('@/views/Errors/RedirectingView.vue'),
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: () => import('@/views/Errors/Forbidden/index.vue'),
    },
    {
        path: '/not-found',
        name: 'FolderNotFound',
        component: () => import('@/views/Errors/FolderNotFound/index.vue'),
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('@/views/Errors/NotFound/index.vue'),
    }
];

export const router = new VueRouter({
    mode: 'history',
    routes,
});
