




import {Vue, Component, Prop} from 'vue-property-decorator';
@Component
export default class VImage extends Vue {
    @Prop() readonly src!: string;
    
    get _src() {
        return this.$mixins.imageURL(this.src)
    }
}
