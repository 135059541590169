import {Actions, Module, Mutations, Getters} from 'vuex-smart-module';
import {VuexMixins} from '@/prototype';
import {router} from "@/router";

class BreadcrumbsState {
    public id: number | string = 1;
    public breadcrumbs = [];
}

class BreadcrumbsMutations  extends Mutations<BreadcrumbsState> {
    public LOAD_BREADCRUMBS_SUCCESS(payload: []) {
        this.state.breadcrumbs = payload
    }
    public CLEAR() {
        this.state.breadcrumbs = []
    }
}

class BreadcrumbsActions extends Actions<BreadcrumbsState, BreadcrumbsGetters, BreadcrumbsMutations, BreadcrumbsActions> {
    public async LOAD_BREADCRUMBS(id: number | string) {
        await VuexMixins.api.folger.breadcrumbs(id ? id : this.state.id)
            .then((res) => this.commit('LOAD_BREADCRUMBS_SUCCESS', res.data.result))
            .catch(() => router.push('/not-found'))
    }
}

class BreadcrumbsGetters extends Getters<BreadcrumbsState> {}

export const Breadcrumbs = new Module({
    state: BreadcrumbsState,
    actions: BreadcrumbsActions,
    mutations: BreadcrumbsMutations
});
