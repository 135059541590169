


















import {Vue, Component, Prop} from 'vue-property-decorator';
const Icon = () => import('../Icon/index.vue');
import {ISetState} from '@/mixins/vuex_mixins';

@Component({
    name: 'Input',
    components: {Icon}
})
export default class Input extends Vue{
    @Prop() readonly name!: string;
    @Prop() readonly change!: Function;
    @Prop() readonly icon!: string;
    @Prop() readonly rules!: Function[];
    @Prop() readonly label!: string;
    @Prop() readonly value!: string | number | any;
    @Prop() readonly error!: string;
    @Prop() readonly type!: string;

    get color(): string {
        return this.error?.length ? 'red' : '#E2E2E2'
    }

    _change(e: Event){
        this.change({
            field: this.name.split('.'),
            value: e
        } as ISetState)
    }
}
