import {Actions, Getters, Mutations, Module} from 'vuex-smart-module';
import {IRegisterUser} from '@/vuex/Register/IRegister';
import {ISetState} from '@/mixins/vuex_mixins';
import {VuexMixins} from '@/prototype';


const defaultRegister = (): IRegisterUser => ({
    'last_name': '',
    name: '',
    'middle_name': '',
    email: '',
    password: '',
    'password_again': '',
})

class RegisterState {
    storeRegister = defaultRegister();
    error: Record<string, string> = {}
}

class RegisterMutations extends Mutations<RegisterState>{
    public REGISTER_ERROR(payload: Record<string, string>) {
        this.state.error = payload
    }

    public CHANGE_STORE_REGISTER(payload: ISetState) {
        this.state.storeRegister = VuexMixins._setState(this.state.storeRegister, payload) as IRegisterUser;
        delete this.state.error[Object.keys(payload)[0]];
    }

    public CLEAN_STORE_REGISTER() {
        this.state.storeRegister = defaultRegister();
    }
}

class RegisterActions extends Actions<RegisterState, RegisterGetters, RegisterMutations, RegisterActions>{

}

class RegisterGetters extends Getters<RegisterState>{

}

export const Register = new Module({
    state: RegisterState,
    mutations: RegisterMutations,
    getters: RegisterGetters,
    actions: RegisterActions
});