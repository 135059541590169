import {IContent, ISelect} from '@/vuex/Folder/IFolder';
// components
import {Getters, Module} from 'vuex-smart-module';
import {FolderMutations, defaultSelect, folder, file} from '@/vuex/Folder/Mutations';
import {FolderActions} from '@/vuex/Folder/Actions';

export class FolderState {

    folder = folder()
    file = file()

    permissions = 0;
    selectViews: ISelectElement[] = []
    select = defaultSelect()
    content: IContent = {
        files: [],
        folders: []
    }

    selectFolderInTrash: number | null = null;

    crumb = false
    openFiles: number | null = null

    loader = true
    loading = false

    selectFolder = {}
    selectFile = {}

    folderLink = ''

    size = 0
    selectedPermissions = 0

    hotKeysContent: ISelect = {
        files: [],
        folders: []
    }

    isMouseClicked = false
    revealContextMenu = false
    hotKeysSelect = false
    uploadingPermissions = 0
    isDragging = false
}

export class FolderGetters extends Getters<FolderState> {
    public GET_FOLDER_CONTENT() {
        return this.state.content;
    }
}

export const Folder = new Module({
    state: FolderState,
    mutations: FolderMutations,
    getters: FolderGetters,
    actions: FolderActions,
});
