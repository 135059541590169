import {Module, Mutations, Actions, Getters} from 'vuex-smart-module';
import {IFolder} from '@/vuex/Folder/IFolder';
import {ICreateFolder} from '@/views/B2B/vuex/Admin/ICreateFolder';
import {VuexMixins} from '@/prototype';

class StoreB2B {
    folderB2B: IFolder = {
        id: 1,
        title: '',
        'created_at': '',
        color: '',
        permissions: 0,
        taggeds: false,
        marked: '',
        parent: 0
    }
    createData: ICreateFolder = {
        name: '',
        key: ''
    }
}

class MutationsB2B extends Mutations<StoreB2B>{
    EDIT_DATA_CREATE_FOLDER(payload: ICreateFolder){
        this.state.createData = payload
    }
    CREATE_SUCCESS(payload: IFolder){
        this.state.folderB2B = payload
    }
}

class ActionsB2B extends Actions<StoreB2B, GettersB2B, MutationsB2B, ActionsB2B >{
    async CREATE_FOLDER(){
        await VuexMixins.api.b2b.create(this.state.createData).then((res) => {
            this.mutations.CREATE_SUCCESS(res.data.result)
        })
    }
}

class GettersB2B extends Getters<StoreB2B> {

}

export const B2B = new Module({
    state: StoreB2B,
    mutations: MutationsB2B,
    actions: ActionsB2B,
    getters: GettersB2B
})
