import anime from 'animejs/lib/anime.es';
import {DirectiveBinding} from 'vue/types/options';
import {VueConstructor} from 'vue/types/umd';

export default class VueAnime {
    static install(Vue: VueConstructor) {
        Vue.directive('anime', {
            bind: function bind(targets: HTMLElement, binding: DirectiveBinding) {
                const opts = Object.assign({}, binding.value, {targets: targets});
                anime(opts);
            },
            update: function bind(targets: HTMLElement, binding: DirectiveBinding) {
                const opts = Object.assign({}, binding.value, {targets: targets});
                anime(opts);
            }
        });
        Vue.prototype.$anime = anime;
    }
}