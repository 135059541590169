import {Mutations, Actions, Getters, Module} from 'vuex-smart-module';
import {VuexMixins} from '@/prototype';
import {IAdminUser, IGroup, IUserRules} from '@/views/Admin/vuex/IAdmin';
import {ICreateUser} from '@/api/user';
import {ISetState} from '@/mixins/vuex_mixins';
import {store} from '@/vuex/store';

const randomPassword = () => {
    return Math.random().toString(36).slice(7) +
        Math.random().toString(36)
            .toUpperCase().slice(7);
}

const defaultUser = (): ICreateUser => ({
    name: '',
    email: '',
    password: randomPassword(),
    'last_name': '',
    'middle_name': '',
    role: '',
    group: [],
})

class AdminState {
    public group: IGroup = {
        id: 1,
        name: 'Без названия'
    }

    public user: IAdminUser = {
        id: 1,
        name: '',
        email: '',
        password: '',
        lastName: '',
        middleName: '',
        role: '',
        group: [],
    }

    storeUser = defaultUser();

    users = []
    groups = []

    roles = ['Пользователь', 'Администратор']

    searchUsersResult: IAdminUser[] = []
    searchGroupsResult: IGroup[] = []

    error: Record<string, string> = {}

    searchType = 'USERS'

    userList: IUserRules[] = []
    groupList: IUserRules[] = []

    selectedUsers: IAdminUser[] = []
}

class AdminMutations extends Mutations<AdminState> {
    SELECT_USERS(users: []) {
        this.state.selectedUsers = users
    }

    SET_SEARCH_TYPE(type: string) {
        this.state.searchType = type
    }

    CHANGE_STORE_USER(payload: ISetState) {
        this.state.storeUser = VuexMixins._setState(this.state.storeUser, payload) as ICreateUser;
        delete this.state.error[Object.keys(payload)[0]];
    }

    UPDATE_USER_FIELDS() {
        this.state.storeUser = {
            name: '',
            email: '',
            password: randomPassword(),
            'last_name': '',
            'middle_name': '',
            role: '',
            group: [],
        }
    }

    UPDATE_USER_FIELDS_FOR_EDIT() {
        this.state.storeUser = {
            id: this.state.user.id,
            name: this.state.user.name,
            email: this.state.user.email,
            'last_name': this.state.user.lastName,
            'middle_name': this.state.user.middleName,
            role: this.state.user.role,
            group: this.state.user.group,
        }
    }

    LOAD_ALL_USERS_SUCCESS(data: []) {
        this.state.users = data
    }

    LOAD_ONE_USER_SUCCESS(data: IAdminUser) {
        this.state.user = data
    }

    EDIT_USER_SUCCESS(data: IAdminUser) {
        this.state.user = data
    }

    LOAD_ALL_GROUPS_SUCCESS(data: []) {
        this.state.groups = data
    }

    LOAD_ONE_GROUP_SUCCESS(data: IGroup) {
        this.state.group = data
    }

    SET_GROUP_NAME(data: string) {
        this.state.group.name = data;
    }

    EDIT_GROUP_SUCCESS(data: IGroup) {
        this.state.group = data
    }

    LOAD_SEARCH_RESULT_USERS_SUCCESS(data: IAdminUser[]) {
        this.state.searchUsersResult = data
    }

    DROP_SEARCH_RESULT_USERS() {
        this.state.searchUsersResult = []
    }

    LOAD_SEARCH_RESULT_GROUPS_SUCCESS(data: IGroup[]) {
        this.state.searchGroupsResult = data
    }

    DROP_SEARCH_RESULT_GROUPS() {
        this.state.searchGroupsResult = []
    }

    LOAD_USER_LIST_SUCCESS(list: IUserRules[]) {
        this.state.userList = list;
    }

    LOAD_GROUP_LIST_SUCCESS(list: IUserRules[]) {
        this.state.groupList = list;
    }

    ADD_USER_TO_USER_LIST(user: IUserRules) {
        this.state.userList.push(user)
    }

    ADD_GROUP_TO_GROUP_LIST(group: IUserRules) {
        this.state.groupList.push(group)
    }

    CHANGE_USER_LIST(list: IUserRules[]) {
        this.state.userList = list
    }

    CHANGE_GROUP_LIST(list: IUserRules[]) {
        this.state.groupList = list
    }
}

class AdminActions extends Actions<AdminState, AdminGetters, AdminMutations, AdminActions> {
    public async LOAD_ALL_USERS(sorting?: string) {
        await VuexMixins.api.user.allUsers(sorting)
            .then((res) => this.mutations.LOAD_ALL_USERS_SUCCESS(res.data.result.content))
    }

    public async LOAD_ONE_USER(id: number | string) {
        await VuexMixins.api.user.oneUser(id)
            .then((res) => this.mutations.LOAD_ONE_USER_SUCCESS(res.data.result.content[0]))
    }

    public async CREATE_USER(payload: ICreateUser) {
        await VuexMixins.api.user.createUser(payload)
            .then((res) => res.data.result)
        await this.actions.LOAD_ALL_USERS()
    }

    public async REGISTER_USER(payload: ICreateUser) {
        await VuexMixins.api.user.createUser(payload)
    }

    public async DELETE_USER(id: string[] | number[]) {
        await VuexMixins.api.user.deleteUser(id)
        await this.actions.LOAD_ALL_USERS()
    }

    public async EDIT_USER(payload: ICreateUser) {
        await VuexMixins.api.user.editUser(payload)
            .then((res) => this.mutations.EDIT_USER_SUCCESS(res.data.result))
        await this.actions.LOAD_ALL_USERS()
    }

    public async ADD_USERS_TO_GROUP(payload: {id: number[]; group: string[]}) {
        await VuexMixins.api.user.addUsersToGroup(payload.id, payload.group)
            .then((res) => res.data.result)
        await this.actions.LOAD_ALL_USERS()
    }

    public LOAD_SEARCH_RESULT_USERS(searchString: string) {
        VuexMixins.api.search.searchUser(searchString)
            .then((res) => {
                if (searchString.length > 2)
                    this.mutations.LOAD_SEARCH_RESULT_USERS_SUCCESS(res.data.result.content)
            })
    }

    public async LOAD_ALL_GROUPS() {
        await VuexMixins.api.user.allGroups()
            .then((res) => this.mutations.LOAD_ALL_GROUPS_SUCCESS(res.data.result.content))
    }

    public async LOAD_ONE_GROUP(id: number | string) {
        await VuexMixins.api.user.oneGroup(id)
            .then((res) => this.mutations.LOAD_ONE_GROUP_SUCCESS(res.data.result.content[0]))
    }

    public async CREATE_GROUP(name: string| number) {
        await VuexMixins.api.user.createGroup(name)
        await this.actions.LOAD_ALL_GROUPS()
    }

    public async EDIT_GROUP(payload: {id: string | number; name: any}) {
        await VuexMixins.api.user.editGroup(payload.id, payload.name)
            .then((res) => this.mutations.EDIT_GROUP_SUCCESS(res.data.result))
        await this.actions.LOAD_ALL_GROUPS()
    }

    public async DELETE_GROUP(id: string | number) {
        await VuexMixins.api.user.deleteGroup(id)
        await this.actions.LOAD_ALL_GROUPS()
    }

    public LOAD_SEARCH_RESULT_GROUPS(searchString: string) {
        VuexMixins.api.search.searchGroup(searchString)
            .then((res) => {
                this.mutations.LOAD_SEARCH_RESULT_GROUPS_SUCCESS(res.data.result.content)
            })
    }

    // Folder permissions
    public async SET_PERMISSIONS_FOR_USERS(payload: {permissions: any; id: number; forAll: boolean}) {
        store.commit('Folder/EDIT_DATA', true)
        await VuexMixins.api.user.userPermissions(payload.permissions, payload.id, payload.forAll)
        store.commit('Folder/EDIT_DATA', false)
    }

    public async SET_PERMISSIONS_FOR_GROUPS(payload: {permissions: any; id: number; forAll: boolean}) {
        store.commit('Folder/EDIT_DATA', true)
        await VuexMixins.api.user.groupPermissions(payload.permissions, payload.id, payload.forAll)
        store.commit('Folder/EDIT_DATA', false)
    }

    public async LOAD_USER_LIST(id: number) {
        await VuexMixins.api.user.userListPermissions(id)
            .then((res) => this.mutations.LOAD_USER_LIST_SUCCESS(res.data.result))
    }

    public async LOAD_GROUP_LIST(id: number) {
        await VuexMixins.api.user.groupListPermissions(id)
            .then((res) => this.mutations.LOAD_GROUP_LIST_SUCCESS(res.data.result))
    }
}

class AdminGetters extends Getters<AdminState> {}

export const AdminVuex = new Module({
    state: AdminState,
    mutations: AdminMutations,
    actions: AdminActions,
    getters: AdminGetters
});