import {Mutations, Module} from 'vuex-smart-module';

export enum EViewType {
    'table' = 'table',
    'card' = 'card',
    'bigCard' = 'big-card'
}

class ViewState {
    type: EViewType | string = (localStorage.getItem('viewType') as string) || EViewType.card
}

class ViewMutations extends Mutations<ViewState>{
    CHANGE_TO_VIEW_TYPE(type: EViewType) {
        localStorage.setItem('viewType', type)
        this.state.type = type;
    }
}

export const ViewType = new Module({
    state: ViewState,
    mutations: ViewMutations
});