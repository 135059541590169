import {Actions, Getters, Module, Mutations} from 'vuex-smart-module'
import {ITree} from '@/vuex/Folder/IFolder';
import {VuexMixins} from '@/prototype';

export enum TreeEnum {
    TREE = 'tree',
    MOVE_TREE = 'moveTree'
}

class TreeState implements ITree{
    tree: IItems[] = [
        {
            id: 1,
            title: 'Все папки',
            to: '/files/1',
            open: false,
            children: []
        }
    ]
    moveTree: IItems[] = []
}

class MutationsTree extends Mutations<TreeState>{
    public SET_CREATE_TREE(payload: {id: string | number; children: {id: string | number; title: string}[]; type: TreeEnum }){
        const editTree = (state: IItems[]): IItems[] => {
            state.map((item) => {
                if(item.id === payload.id){
                    item.children = payload.children.map(items => ({
                        open: false,
                        to: `/files/${items.id}`,
                        children: [],
                        ...items,
                    }))
                } else {
                    editTree(item.children ? item.children : [])
                }
            })
            return state;
        }

        console.log(payload.type)

        this.state[payload.type] = editTree(this.state[payload.type])

        return Promise.resolve('')
    }

    public OPEN_TREE(payload: {items: IItems; type: TreeEnum}) {
        const close = (items: IItems[]) => {
            items.map(item => {
                item.open = false;
                close(item.children || [])
            })
        }

        const open = (state: IItems[], id?: string | number) => {
            state.map(item => {
                if (item.id === id && item.open) {
                    item.open = false;
                    close(item.children || [])
                } else if (item.id === id && !item.open) {
                    item.open = true
                } else {
                    open(item.children || [], id)
                }
            })

            return state
        }

        this.state[payload.type] = open(this.state[payload.type], payload.items.id);
    }
}

class ActionsTree extends Actions<TreeState, Getters<TreeState>, MutationsTree, ActionsTree> {
    public async LOAD_TREE_FOLDER(payload: {id: string | number; type: TreeEnum}){
        await VuexMixins.api.folger.tree(payload.id || this.state.tree[0].id).then((res) => {
            this.mutations.SET_CREATE_TREE({id: payload.id || this.state.tree[0].id,
                children: res.data.result.content.folders,
                type: payload.type
            })
        })
    }

    public async SET_OPEN_FOLDER(payload: {items: IItems; type: TreeEnum}) {
        return await new Promise<void>((resolve, reject) => {
            this.mutations.OPEN_TREE({
                items: payload.items,
                type: payload.type
            })
            resolve()
        })
    }
}

export const Tree = new Module({
    state: TreeState,
    mutations: MutationsTree,
    actions: ActionsTree
})
