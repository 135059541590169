




import {Component, Prop, Vue} from 'vue-property-decorator';
import * as tsx from 'vue-tsx-support'

@Component({
    name: 'Icon'
})
export default class Icon extends Vue {
    _tsx!: tsx.DeclareProps<{ icon?: string}>
    @Prop() readonly icon: string | undefined;


}
