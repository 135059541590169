







import {Vue, Component, Prop} from 'vue-property-decorator';
const Diamond = () => import('vue-loading-spinner/src/components/Diamond.vue')

@Component({
    name: 'Btn',
    components: { Diamond}
})
export default class Btn extends Vue{
    @Prop() readonly loader: boolean | undefined;
}
