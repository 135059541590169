import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {store } from '@/vuex/store'
import { refreshCatch} from '@/main';
import {Toast} from '@/plugin/toast';

import {
    BASE_URL,
    AxiosHeader,
    HEADER_CONTENT_TYPE,
} from '@/constants/Api';



interface ITokenModel {
    createdAt: string;
    ttl: number;
    token: string;
}

export const configureApi = (): AxiosInstance => {

    // tslint:disable-next-line:no-console

    const instance: AxiosInstance = axios.create({
        baseURL: BASE_URL + '/api',
        headers: {
            'Content-Type': HEADER_CONTENT_TYPE,
        },
    });

    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        const urlParams = new URLSearchParams(window.location.search);
        config.headers.common[AxiosHeader.AUTH_TOKEN] = store.state.Auth.accessToken.token ? 'Bearer ' + store.state.Auth.accessToken.token : null;
        config.headers[AxiosHeader.SECRET_KEY] = urlParams.get('key');
        return config;
    }, (error: any) => {
        return Promise.reject(error);
    });

    let attemptCount = 1;
    let attemptRefreshCount = 0;

    instance.interceptors.response.use((response) => {
        if (response.status !== 204 &&
            response.data?.status?.message !== 'success' && response.data?.status?.message.length !== 0){
            if (response.data?.result?.renamed_files) {
                const result = response.data?.result.renamed_files;
                const message: string = result.length === 1 ? `Файл "${result[0].origin_name}" успешно переименован в "${result[0].new_name}"` : 'Файлы успешно переименованы'
                Toast.fire({icon: 'success', title: message})
            } else {
                Toast.fire({icon: 'success', title: response.data?.status?.message})
            }

        }
        return response;
    }, (error: any) => {
        if (error && error.config && error.response && error.response.status) {
            //если истек токен - обновляем его
            if (error.response.status === 401 || (error.response.status === 403 && !attemptRefreshCount++ && store.state.Auth.accessToken.token)) {
                if (!store.state.Auth.refresh) {
                    return store.dispatch('Auth/refresh').then(() => {
                        error.config.headers[AxiosHeader.AUTH_TOKEN] = store.state.Auth.accessToken.token ? 'Bearer ' + store.state.Auth.accessToken.token : null;
                        return instance.request(error.config);
                    }).catch(() => {
                        refreshCatch('login')
                    })
                } else {
                    if (attemptRefreshCount < 1) {
                        error.config.headers[AxiosHeader.AUTH_TOKEN] = store.state.Auth.accessToken.token ? 'Bearer ' + store.state.Auth.accessToken.token : null;
                        return instance.request(error.config);
                    }

                }
            }
            //если произошла ошибка на сервере - пробуем еще три раза
            else if (error.response.status === 500 && attemptCount++ < 3){
                error.config.headers[AxiosHeader.ATTEMPT_INDEX] = attemptCount;
                return instance.request(error.config);
            } else {
                if (error.response.data.status.message !== '') {
                    Toast.fire({ icon: 'error', title: error.response.data.status.message,})
                }
            }
        }
        return Promise.reject(error);
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    instance.accessToken = store.state.Auth.accessToken.token;

    return instance;
};
