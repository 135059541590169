import {Mutations, Module} from 'vuex-smart-module';
import * as jQuery from 'jquery'

class RightMenuState {
    public opened =  false;
}

class RightMenuMutations extends Mutations<RightMenuState>{
    public OPEN_MENU() {
        jQuery('.tools-panel').css({'width': 'calc(100% - 300px)'})
        jQuery('.page__content').css({'width': 'calc(100% - 266px)'})
        this.state.opened = true;
    }
    public CLOSE_MENU() {
        jQuery('.tools-panel').css({'width': '100%'})
        jQuery('.page__content').css({'width': '100%'})
        this.state.opened = false;
    }
}

export const RightMenuVuex = new Module({
    state: RightMenuState,
    mutations: RightMenuMutations
});