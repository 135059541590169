import Selection from '@simonwep/selection-js';
import {store} from '@/vuex/store';

export function removeDuplicates(arr: any) {

    const result: any[] = [];
    const duplicatesIndices: any[] = [];

    // Перебираем каждый элемент в исходном массиве
    arr.forEach((current: any, index: number) => {

        if (duplicatesIndices.includes(index)) return;

        result.push(current);

        // Сравниваем каждый элемент в массиве после текущего
        for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {

            const comparison = arr[comparisonIndex];
            const currentKeys = Object.keys(current);
            const comparisonKeys = Object.keys(comparison);

            // Проверяем длину массивов
            if (currentKeys.length !== comparisonKeys.length) continue;

            // Проверяем значение ключей
            const currentKeysString = currentKeys.sort().join('').toLowerCase();
            const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase();
            if (currentKeysString !== comparisonKeysString) continue;

            // Проверяем индексы ключей
            let valuesEqual = true;
            for (let i = 0; i < currentKeys.length; i++) {
                const key = currentKeys[i];
                if ( current[key] !== comparison[key] ) {
                    valuesEqual = false;
                    break;
                }
            }
            if (valuesEqual) duplicatesIndices.push(comparisonIndex);

        } // Конец цикла
    });
    return result;
}

const selectPlugin = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? null :
    new Selection({
        class: 'selection',
        selectables: ['.selectedElement'],
        scrollSpeedDivider: 100,
        manualScrollSpeed: 100,
        boundaries: ['.page__content']
})

export function selectElement(e: MouseEvent | HTMLElement, value: any) {
    if (selectPlugin !== null) {
        let el;
        if (e instanceof MouseEvent) {
            el = e.target.closest('.selectedElement') as HTMLElement
        } else {
            el = e;
        }
        const selected = selectPlugin.getSelection()
        const idx = selected.indexOf(el)

        if(idx !== -1) {
            el.classList.remove('selected')
            selectPlugin.removeFromSelection(el)
        } else {
            el.classList.add('selected')
            selected.push(el)
            selectPlugin.select(selected as HTMLElement[])
        }
        if(value.type === 'folder') {
            store.commit('Folder/FOLDER_SELECTED_CLICK', value.id)
            store.commit('Folder/COLLECT_SELECTED_ELEMENTS',
                {id: value.id, permissions: value.permissions, type: value.type}
            )
        }
        if(value.type === 'file') {
            store.commit('Folder/FILE_SELECTED_CLICK', value.id)
            store.commit('Folder/COLLECT_SELECTED_ELEMENTS',
                {id: value.id, permissions: value.permissions, type: value.type}
            )
        }
    } else console.log('mobile')
}


export default selectPlugin
