import {$http} from '@/main';
//interface
import {IAuthUser} from '@/vuex/Auth/ILogin';
import {AxiosInstance} from 'axios';

class LoginApi {
    login(data: IAuthUser) {
        return $http.post('/user/login', data)
    }
    refresh(token: string){
        return $http.post('/user/refresh', {
            'refresh_token': token
        })
    }
}

export default new LoginApi();