import {$http} from '@/main';
import {AxiosPromise} from 'axios';

export interface ICreateUser {
    id?: string | number;
    name: string;
    email: string;
    password?: string;
    last_name?: string | null;
    middle_name?: string | null;
    role: string;
    group?: string[];
}

interface IUserApi {
    userData(): AxiosPromise;
    allUsers(): AxiosPromise;
    oneUser(id: string | number): AxiosPromise;
    createUser(payload: ICreateUser): AxiosPromise;
    deleteUser(id: string[] | number[]): AxiosPromise;
    editUser(payload: ICreateUser): AxiosPromise;
    addUsersToGroup(id: number[], group: string[]): AxiosPromise;
    allGroups(): AxiosPromise;
    oneGroup(id: string | number): AxiosPromise;
    createGroup(name: string | number): AxiosPromise;
    editGroup(id: string | number, name: string | number): AxiosPromise;
    deleteGroup(id: string | number): AxiosPromise;
    userPermissions(permissions: any, id: number, forAll: boolean): AxiosPromise;
    groupPermissions(permissions: any, id: number, forAll: boolean): AxiosPromise;
    userListPermissions(id: number): AxiosPromise;
    groupListPermissions(id: number): AxiosPromise;
}

class UserApi implements IUserApi {
    userData(): AxiosPromise {
        return $http.post('/user/content')
    }

    allUsers(sorting?: string): AxiosPromise {
        return $http.post('/user/get', {sorting})
    }

    oneUser(id: string | number): AxiosPromise {
        return $http.post('/user/get', {id})
    }

    createUser(payload: ICreateUser): AxiosPromise {
        return $http.post('/user/create', payload)
    }

    deleteUser(id: string[] | number[]): AxiosPromise {
        return $http.post('/user/delete', {id})
    }

    editUser(payload: ICreateUser): AxiosPromise {
        return $http.post('/user/edit', payload)
    }

    addUsersToGroup(id: number[], group: string[]): AxiosPromise {
        return $http.post('/user/addUsersToGroup', {id, group})
    }

    allGroups(): AxiosPromise {
        return $http.post('/group/get')
    }

    oneGroup(id: string | number): AxiosPromise {
        return $http.post('/group/get', {id})
    }

    createGroup(name: string | number): AxiosPromise {
        return $http.post('/group/create', {name})
    }

    editGroup(id: string | number, name: string | number): AxiosPromise {
        return $http.post('/group/edit', {id, name})
    }

    deleteGroup(id: string | number): AxiosPromise {
        return $http.post('/group/delete', {id})
    }

    userPermissions(permissions: any, id: number, forAll: boolean): AxiosPromise {
        return $http.post('/folder/setPermissions', {permissions, id, forAll})
    }

    groupPermissions(permissions: any, id: number, forAll: boolean): AxiosPromise {
        return $http.post('/group/setGroupsPermissions', {permissions, id, forAll})
    }

    userListPermissions(id: number): AxiosPromise {
        return $http.post('/folder/getUserList', {id})
    }

    groupListPermissions(id: number): AxiosPromise {
        return $http.post('/group/getGroupList', {id})
    }
}

export default new UserApi();