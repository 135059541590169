const _data = {}

function setValues(data: any, binding: any) {
    data[binding.expression] = binding.value
}

export function containsDirective(arr = [], directive: any) {
    // eslint-disable-next-line @typescript-eslint/no-for-in-array
    for (const a in arr) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (arr[a].name === directive)
            return arr[a]
    }
    return false
}

const containsCase =
    (arr = [], case1: string) => containsDirective(arr, 'case')

const containsDefault =
    (arr = []) => containsDirective(arr, 'default')

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
function toggleDefaultElement(binding: any, vnode: any, { show }) {
    const children = vnode.children
    for (const node of children) {
        if (node.data) {
            if (containsDefault(node.data.directives)) {
                const display = show
                    ? node.elm.getAttribute('data-initial-display')
                    : 'none'
                node.elm.style.display = display
            }
        }
    }
}

function revealElementWithInitialDisplay(element: any) {
    const initialDisplay = element.getAttribute('data-initial-display')
    element.style.display = initialDisplay !== 'none'
        ? initialDisplay
        : 'block'
}

function processSwitch(el: any, binding: any, vnode: any, data: any) {
    let matched = false
    const children = vnode.children
    for (const node of children) {
        if (node.data) {
            const caseDirective = containsCase(node.data.directives, 'case')
            if (caseDirective) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                if (caseDirective.value === data[binding.expression]) {
                    revealElementWithInitialDisplay(node.elm)
                    toggleDefaultElement(binding, vnode, { show: false })
                    matched = true
                } else {
                    node.elm.style.display = 'none'
                }
            }
        }
    }

    if (!matched) {
        toggleDefaultElement(binding, vnode, { show: true })
    }
}

function saveInitialDsplayToDataAttr(elements: any) {
    for (const child of elements) {
        child.setAttribute('data-initial-display', child.style.display)
    }
}

const vSwitch = {
    bind(el: any, binding: any) {
        setValues(_data, binding)
    },

    inserted(el: any, binding: any, vnode: any) {
        saveInitialDsplayToDataAttr(el.children)
        processSwitch(el, binding, vnode, _data)
    },

    update(el: any, binding: any) {
        setValues(_data, binding)
    },

    componentUpdated(el: any, binding: any, vnode: any) {
        processSwitch(el, binding, vnode, _data)
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const vCase = () => {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const vDefault = () => {}

export { vSwitch, vCase, vDefault }

export default {
    install(Vue: any, options: any) {
        Vue.directive('switch', vSwitch)
        Vue.directive('case', vCase)
        Vue.directive('default', vDefault)
    }
}