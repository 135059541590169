import {ICreateFolder} from '@/views/B2B/vuex/Admin/ICreateFolder';
import {AxiosPromise} from 'axios';
import {$http} from '@/main';

interface IB2BApi {
    create(payload: ICreateFolder): AxiosPromise;
}

class B2BApi implements IB2BApi{
    create(payload: ICreateFolder): AxiosPromise {
        return  $http.post(`/b2b/admins/${payload.name}`, {
            key: payload.key
        })
    }
}

export default new B2BApi();
