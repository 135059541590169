import { defineStore } from 'pinia'
import { useSharingStore } from '@/store/sharing';

export const useFolderStore = defineStore('folder', () => {
    const sharing = useSharingStore();

    const getAvailableRootFolder = () => {
        if (sharing.token && sharing.folderId) {
            return sharing.folderId;
        }

        // Проверка некорректна, но сейчас подобная логика используется в других местах.
        if (localStorage.getItem('accessToken')) {
            // Идентификатор корневой папки.
            return 1;
        }

        return null;
    };

    return {
        getAvailableRootFolder
    }
});
