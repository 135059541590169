import Vue from 'vue';
import VueI18n from 'vue-i18n';
import RU from '@/assets/lang/ru.json';

Vue.use(VueI18n);

const numberFormats = {
    ru: {
        currency: {
            style: 'currency', currency: 'RUB',
        },
    },
};

export const i18n = new VueI18n({
    locale: localStorage.lang,
    fallbackLocale: 'ru',
    messages: {
        ru: RU,
    },
    numberFormats,
});
