import {AxiosPromise} from 'axios';
import {$http} from '@/main';

interface ISearchApi {
    search(searchString: string): AxiosPromise;
}

class SearchApi implements ISearchApi{
    search(searchString: string): AxiosPromise {
        return $http.post('/search', {
            count: 5,
            'search_string': searchString,
            'search_type': '_all',
            sorting: 'title',
            'sort_order': 'asc'
        })
    }

    searchAll(searchString: string): AxiosPromise {
        return $http.post('/search', {
            count: null,
            'search_string': searchString,
            'search_type': '_all',
            sorting: 'title',
            'sort_order': 'asc'
        })
    }

    searchUser(searchString: string): AxiosPromise {
        return $http.post('/user/get', {
            'search': searchString
        })
    }

    searchGroup(searchString: string): AxiosPromise {
        return $http.post('/group/get', {
            'search': searchString
        })
    }
}

export default new SearchApi();
