export enum AxiosHeader {
    AUTH_TOKEN = 'Authorization',
    DEVICE_TYPE = 'X-Device-Type',
    DEVICE_TOKEN = 'X-Device-Token',
    ATTEMPT_INDEX = 'X-Attempt-Index',
    SECRET_KEY = 'X-Secret-Key',
}

export enum RequestStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    QUESTION = 'question'
}

export const BASE_URL = process.env.VUE_APP_BACKEND_BASE_URL || 'https://api.contedisk.com';
export const DEVICE_TYPE_WEB = 'WEB';
export const HEADER_CONTENT_TYPE = 'application/json';
