import {BASE_URL} from '@/constants/Api';

export interface IMixins {
    createRange(length: number): number[];
    lightenDarkenColor(col: string, amt: number): string;
    imageURL(url: string): string;
    traverseFileTree(item: DataTransferItem, path?: string): void;
}

export default class Mixins implements IMixins {
    createRange(length: number): number[] {
        return Array.from({ length }, (v, k) => k);
    }

    traverseFileTree(item: any, path?: string) {
        path = path || '';
        if (item.isFile) {
            // Get file
            /*item.file((file: File) => console.log('File:', path + file.name, file));*/
        } else if (item.isDirectory) {
            // Get folder contents
            const dirReader = item.createReader();
            dirReader.readEntries((entries: any) => {
                console.log(entries)
                for (let i=0; i<entries.length; i++) {
                    this.traverseFileTree(entries[i], path + item.name + '/');
                }
            });
        }
    }

    lightenDarkenColor(col: string, amt: number): string {

        let usePound = false;

        if (col.startsWith('#')) {
            col = col.slice(1);
            usePound = true;
        }

        const num = parseInt(col,16);

        let r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if  (r < 0) r = 0;

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if  (b < 0) b = 0;

        let g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound?'#':'') + (g | (b << 8) | (r << 16)).toString(16);
    }
    imageURL(url: string): string{
        // process.env.NODE_ENV === 'development' ? url = BASE_URL + url : url
        url.startsWith('/storage/') ? url = BASE_URL + url : url
        return url
    }
}
