import {Actions, Getters, Mutations, Module} from 'vuex-smart-module';

import {IUser} from '@/vuex/User/IUser';
import api from '@/api/user'

class UserState implements IUser {
    [key: string]: any;
    id!: number;
    email!: '';
    name!: '';
    group: [];
    role: null;

    constructor() {
        this.email = '';
        this.id = NaN;
        this.name = '';
        this.group = [];
        this.role = null;
    }

    isAdmin = false
}


class UserMutations extends Mutations<UserState> {
    USER_SUCCESS(payload: IUser) {
        Object.keys(payload).map((k) => {
           this.state[k] = payload[k]
        })
        if (payload.role === 'Администратор') this.state.isAdmin = true
        if (payload.role !== 'Администратор') this.state.isAdmin = false
    }
}

class UserGetters extends Getters<UserState> {
    public getUser() {
        return {
            id: this.state.id,
            email: this.state.email,
            role: this.state.role,
            groups: this.state.group
        };
    }
}

class UserActions extends Actions<UserState, UserGetters, UserMutations, UserActions> {
    async SET_LOAD_USER() {
        await api.userData().then(res => {
            this.mutations.USER_SUCCESS(res.data.result)
        })
    }
}

export const User = new Module({
    state: UserState,
    mutations: UserMutations,
    actions: UserActions,
    getters: UserGetters
});
