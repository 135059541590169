import Vue from 'vue';
//module
import $ from 'jquery';
import VueAnime from '@/plugin/anime';
import Mixins from '@/mixins/component_mixins';
import {CVuexMixins} from '@/mixins/vuex_mixins';
import VueComponents from '@/components/index';
import VueOutside from '@/plugin/outside';
import VueSwitch from '@/plugin/switch'
import VueZoomer from 'vue-zoomer'
import {Toast} from '@/plugin/toast';
//vuex mixins
export const VuexMixins = new CVuexMixins()
//use
Vue.use(VueAnime);
Vue.use(VueOutside);
Vue.use(VueComponents);
Vue.use(VueSwitch);
Vue.use(VueZoomer);
//prototype
Vue.prototype.$jquery = $;
Vue.prototype.$mixins = new Mixins();
Vue.prototype.$toast = Toast

localStorage.getItem('columnName') === null ? localStorage.setItem('columnName', 'title') : null;
localStorage.getItem('sort_order') === null ? localStorage.setItem('sort_order', 'asc') : null;
localStorage.getItem('sorting') === null ? localStorage.setItem('sorting', 'created_at') : null;
