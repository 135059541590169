import {Actions, Getters, Mutations, Module} from 'vuex-smart-module';

class LocalState {
    public locale = 'ru';
}

class LocaleMutations extends Mutations<LocalState> {
    public SELECT_LOCALE(payload: string) {
        this.state.locale = payload;
    }
}

class LocaleGetters extends Getters<LocalState> {

}

export const Locale = new Module({
    state: LocalState,
    mutations: LocaleMutations,
    getters: LocaleGetters,
});
