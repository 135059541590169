import Vue from 'vue';
import Vuex from 'vuex';
import {createStore, Module} from 'vuex-smart-module';
// MODULE
import {Locale} from '@/vuex/Locale';
import {Auth} from '@/vuex/Auth';
import {Folder} from '@/vuex/Folder/store';
import {ContextMenu} from '@/vuex/ContextMenu';
import {User} from '@/vuex/User';
import {Tree} from '@/vuex/Treeviews';
import {Modal} from '@/vuex/Modal';
import {Breadcrumbs} from '@/vuex/Breadcrumbs';
import {SearchContent} from '@/vuex/Search';
import {RightMenuVuex} from '@/vuex/RightMenu';
import {ViewType} from '@/vuex/ViewTypes';
import {B2B} from '@/views/B2B/vuex/Admin';
import {AdminVuex} from '@/views/Admin/vuex';
import {AutoTree} from '@/vuex/AutoOpenTree';
import {Register} from '@/vuex/Register';

Vue.use(Vuex);

const root = new Module({
    modules: {
        Locale,
        Auth,
        Register,
        Folder,
        ContextMenu,
        User,
        Tree,
        Modal,
        B2B,
        Breadcrumbs,
        SearchContent,
        RightMenuVuex,
        ViewType,
        AdminVuex,
        AutoTree
    },
});

export const store = createStore(
    root,
    {
        strict: process.env.NODE_ENV !== 'production',
    },
);
