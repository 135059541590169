import Swal from 'sweetalert2'
import $ from 'jquery'

export const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    onRender(popup: HTMLElement) {
        $('#swal2-title').css({ fontFamily: 'Inter, HelveticaNeueCyr, sans-serif' })

        if (popup.classList.contains('swal2-icon-error')){
            $('.swal2-icon-error').css({background: '#fb3d3d'})
            $('#swal2-title').css({fontFamily: 'Inter, HelveticaNeueCyr, sans-serif', color: '#fff'})
            $('.swal2-icon.swal2-error').css({borderColor: '#fff', color: '#fff'})
            $('.swal2-timer-progress-bar').css({background: '#ffffff6b'})
            $('.swal2-x-mark-line-left').css({background: '#fff'})
            $('.swal2-x-mark-line-right').css({background: '#fff'})
        }
    }
})