import {VueConstructor} from 'vue';
import Icon from '@/components/Icon/index.vue';
import Btn from '@/components/Button/index.vue';
import Input from '@/components/Input/index.vue';
import Image from '@/components/VImage/index.vue'
//components
export default class VueComponents {
    static install(Vue: VueConstructor) {
        //решение не лучшее но рабочее, надо подробнее по искать как это автоматизировать, пока проблема что TS должен занть точные пропсы класса
        Vue.component('Icon', {extends: Icon});
        Vue.component('Btn', {extends: Btn});
        Vue.component('Input', {extends: Input});
        Vue.component('VImage', {extends: Image});
    }
}