import {Actions, Module, Mutations, Getters} from 'vuex-smart-module';
import {VuexMixins} from '@/prototype';
import {IContent} from '@/vuex/Folder/IFolder';

class SearchState {
    searchString = '';
    searchResult: IContent | object = {
        folders: [],
        files: []
    };
}

class SearchMutations extends Mutations<SearchState> {
    LOAD_SEARCH_RESULT_SUCCESS(payload: IContent) {
        this.state.searchResult = payload
    }

    LOAD_SEARCH_RESULT_FAILED() {
        this.state.searchResult = {};
        this.state.searchString = ''
    }

    UPDATE_SEARCH_RESULTS() {
        this.state.searchResult = {
            folders: [],
            files: []
        };
    }
}

class SearchActions extends Actions<SearchState, SearchGetters, SearchMutations, SearchActions> {
    public LOAD_SEARCH_RESULT(searchString: string) {
        VuexMixins.api.search.search(searchString)
            .then((res) => {
                if (res.data.result) this.mutations.LOAD_SEARCH_RESULT_SUCCESS(res.data.result.body.content)
            })
            .catch(() => {
                this.mutations.LOAD_SEARCH_RESULT_FAILED()
            })
    }
}

class SearchGetters extends Getters<SearchState> {

}

export const SearchContent = new Module({
    state: SearchState,
    actions: SearchActions,
    mutations: SearchMutations
})
